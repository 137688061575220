//
// custom-styles.scss
//

.status {
  padding: 4px 5px !important;
  margin-top: 0px !important;
  &.ProgressStep {
    background-color: #f5f6fa;
    box-shadow: none;
    border-color: #f5f6fa;
  }
}

label {
  font-size: 13px;
  margin-bottom: 0.2rem;
}
//------- BARI -------//@at-root
.btn-info {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  box-shadow: none;
  &:hover {
    background-color: #545454;
    border-color: #545454;
    box-shadow: none;
  }
}
.badge {
  padding: 0.5em 0.6em;
  line-height: 1;
  border-radius: 0.2rem;
}
.bg-primary {
  background-color: #bcddf6 !important;
  color: #206699;
}
.bg-danger {
  background-color: #f1d3d3 !important;
  color: #ca1f1f;
}
.bg-danger1 {
  background-color: #ca1f1f !important;
  color: #ffffff;
}
.bg-success {
  background-color: #b8e2b8 !important;
  color: #0e7c35;
}
.bg-success1 {
  background-color: #f0fcf8 !important;
  color: black;
  border: 1px solid #50c0a8 !important;
}
.bg-warning {
  background-color: #fbe4a3 !important;
  color: #a07c14;
}
.bg-dark {
  background-color: #a9bbed8c !important;
  color: #495b8e !important;
}

.bg-soft-dark {
  background-color: rgb(219 219 220) !important;
  color: #5a5a5a;
}
.bg-soft-success {
  background-color: #c8f1c8 !important;
  color: #11831a;
}
.notLs {
  background: #fff7d3;
}
.netTotal {
  color: green;
}
.noTxtWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: unset;
  width: 102px;
}
.mapSize {
  width: 100%;
}
.mapSize img {
  width: 100%;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
  width: 543px;
}
// .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
//   width: 90% !important;
// }

.img-thumbnail1 {
  border: 1px solid #e9e9ef !important;
  border-radius: 4px;
  cursor: pointer;
}
.highlightInfo {
  float: left;
  font-size: 11px;
  color: #1f8c51;
  font-weight: 600;
  width: 100%;
  display: block;
  overflow: hidden;
}
.highlight {
  font-size: 7px;
  font-weight: 600;
}
.highlightPara {
  display: block;
  overflow: hidden;
  word-break: break-word;
}
.ReturnInfo1:hover .highlightInfo {
  overflow: auto;
}
.ReturnInfo1:hover .highlightInfo5 {
  overflow: auto;
}
.ReturnInfo1:hover .highlightPara {
  overflow: auto;
}

.highlightInfo1 {
  display: block;
  font-size: 11px;
  color: #1f8c51;
  width: 100%;
}
.highlightInfo5 {
  float: left;
  font-size: 11px;
  padding: 0px;
  width: 100%;
  display: block;
  overflow: hidden;
  color: #ca1f1f;
  font-style: italic;
}
.version {
  margin-top: 5px;
}
.highlightInfo3 {
  font-size: 11px;
  color: #1f8c51;
  font-weight: 600;
  width: 100%;
}
.highlightInfo2 {
  display: block;
  font-size: 11px;
  width: 100%;
}

.ReturnInfo1 {
  max-width: 215px;
}
.proWidth {
  height: 4px;
  width: 75%;
}
.reviewSpan {
  box-shadow: 0px 1px 2px #28282833;
  padding: 0px 2px;
}
.deliveryreviewSpan {
  box-shadow: 0px 1px 2px #28282833;
  padding: 6px 7px;
  font-size: 11px;
  color: rgb(31, 29, 29) !important;
}
.failedBtn {
  background-color: red;
  color: white;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.successBtn {
  background-color: green;
  color: white;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
#outer {
  width: 100%;
}
.inner {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.paymentDetailSec {
  label {
    display: block;
  }
}
.dh-custom-tab {
  margin: 0;
}
.dh-TabRow {
  padding: 8px 8px;
  border: #b6c3d0 solid 1px;
  margin: 0 0px;
  margin-bottom: 5px;
  border-radius: 4px;
  background: #e8f1fa;
}
.refno {
  cursor: pointer;
}
.mr-2 {
  margin-right: 10px;
}
.noWrapText {
  max-width: 202px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.customerComment:hover {
  overflow: auto;
  overflow-y: hidden;
}
.noWrapText2 {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.noWrapText1 {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.orderViewButton {
  float: right;
  margin-top: 36px;
}
.searchIcon1 {
  margin-top: -11px;
  position: absolute;
  top: 50%;
  right: 10px;
}
.region {
  width: 10%;
}

.rs-picker-menu {
  z-index: 999999 !important;
}
.deviceDataBlock {
  float: left;
  width: 100%;
  border: #e3dab4 solid 1px;
  padding: 5px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #f9f7ed;
  span {
    float: left;
  }
}
.refColor {
  color: black;
}
.formAlignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.headPadding {
  padding: 16px 0px 5px 16px;
}
.rowCenter {
  justify-content: center;
}
.marginRiight {
  margin-right: 8px;
}
.importError {
  color: rgb(255, 0, 0);
}
.imageDiv {
  margin-bottom: 10px;
}
.imageContent {
  margin-bottom: 17px;
}
.itemBx {
  width: 360px;
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.p-Wrap {
  margin-top: 0;
  max-width: 300px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.shippingMethodStyle {
  display: inline-block;
  background-color: #d4e4f5 !important;
  color: #000000;
  padding: 5px;
  border: 1px solid #88b6d8;
  border-radius: 3px;
  // width:145px;
  font-size: 13px;
}

#text,
#icon {
  line-height: 20px;
}
#icon {
  vertical-align: middle;
  
}
.paraFont {
  font-size: 11px;
}
.statusDetail {
  height: 100%;
}
.catbuttonDropdown {
  min-width: 100% !important;
}
.btn-success {
  background-color: #249a6a !important;
  border-color: #249a6a !important;
}
.icons{
  display: flex;
  flex-direction: row;
 
}
.icon1 {
  border: 1px solid #33333375;
  opacity: 1;
  width: 40px;
  padding: 3px 39px 3px 10px!important;
  border-radius: 5px;
  margin-left:10px;


img {
  width: 20px;
  height: 20px;
 
 
}
}
.previewButton{
  width:100%;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834)
      no-repeat;
  z-index: 1;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 9999;
  width: 100%;
      height: 100vh;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.4;
}
.productExport{
  display:flex;
  justify-content: flex-end;
  
}
@media (max-width: 390px) {
  .DashbordFliter1{
    grid-gap: 0.2rem !important;
    gap: 0.2rem !important;
  }
}
.search-magnifier-icon{
  cursor:pointer;
}
.card_header{
background: #F2F2F2 0% 0% no-repeat padding-box;
}
.deliverySchedule{
  border-bottom: 1px solid #80808052;
padding-bottom:10px;
}
.checkoutSwiper {
  
    padding-bottom: 12px;
    margin-bottom: 8px;
  
}

.ChooseDeliveryDateSlot {
  overflow: auto;
  margin-top: .5rem;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding:0px !important;
    li{
      text-align: center;
      list-style-type: none;
      padding: 0.5rem;
      width: calc(16.5% - 0.5rem);
      background: #f6f6f8;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      grid-gap: 0.1rem;
      grid-gap: 0.1rem;
      gap: 0.1rem;
      flex-direction: column;
      cursor: pointer;
      line-height: 15px;
    &:first-child {
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      opacity: 0.4;
      cursor: not-allowed;
    }
    
    &.Active{
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      cursor: pointer;
    }
    }
}


 
  .PickConvenientTimeSlot{
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    padding: 0px;
    li{
      padding: 0.35rem 0rem;
    background: #f6f6f8;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    width: calc(33% - 0.5rem);
    text-align: center;
    list-style-type: none;
    &.Active{
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      cursor: pointer;
    }
    }
  }
 
  .cancelBtn{
    margin-right:15px;
  }
  .textAlignLeft{
    text-align: left;
    display:flex;
  }
  .scheduleImage{
    display:flex;
    gap:1rem;
    span{
      display: flex;
      align-items: center;
      color: #2c7651;
  }
    
  }
  .customerComment{
    border:1px solid #00000033;
    padding: 3px 0px 3px 8px;
    border-radius: 3px;
    display: flex;
    gap: 0.3rem;
    line-height: 17px;
}
.commentSec{
  width:100px;
}
.colWidth{
  width:200px;
}


.item-container{
  background-color: #ffffff;
    border: 1px solid #00000024;
    margin-bottom: 15px;
    border-radius: 5px;
    display:flex;
  
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
    span{
      padding-left:10px;
    }
  

  }
  .dragImage{background-color: #E7EBEE;
  padding: 15px;
  }
  .dragImage2{
    padding-right: 10px;
  }
.cardStyle{
  display:flex;
  flex-direction: column;
}
.cardBackground{
  background-color: #F6F6F8;
  border: 1px solid #E3EBF9;
border-radius: 6px;
opacity: 1;
}
.catMain{
  display:flex;
  gap:1rem;
}
.catHeight{
  height:90vh;  
}
 
.catOption{
  min-width:196px;
}

@media (max-width: 808px) {
 
  .catOption{
  display:none;
  }
} 
